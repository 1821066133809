/* eslint react/forbid-prop-types: 0 */
import React, {useState} from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {boldTextColor} from '../modules/colors';
import Input from './Input';
import Button from './Button';
import isValidSpreadsheetURL from '../modules/isValidSpreadsheetURL';
import media from '../modules/media';

const Wrapper = styled.div`
  ${media.tablet`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
  `}
`;

const Title = styled.h1`
  color: ${boldTextColor};
  font-size: 40px;

  ${media.tablet`font-size: 32px;`};
  ${media.phone`font-size: 24px;`};
`;

const Subtitle = styled.div`
  font-size: 20px;
  margin: 20px 0;
  padding: 10px;
  white-space: pre-wrap;

  ${media.tablet`
    font-size: 18px;
    margin: 10px 0;
  `};

  ${media.phone`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  `};
`;

const InputWrapper = styled.div`
  display: flex;

  input {
    width: 300px;
    margin-right: 10px;
  }

  ${media.phone`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;

    input {
      width: auto;
      margin: 0;
    }
  `}
`;

const Error = styled.div`
  color: red;
  display: ${props => (props.show ? 'block' : 'none')};
`;

const CreateInput = ({title, subtitle, placeholder, onClick, buttonText, disableButton, buttonHelp, style}) => {
  const [error, setError] = useState(null);

  const handleClick = () => {
    const value = document.querySelector('input[name="spreadsheet"]').value;
    const spreadsheetId = isValidSpreadsheetURL(value);
    if (!spreadsheetId) {
      setError('Invalid URL. Please open a Google Spreadsheet, copy the browser URL and paste it here');
      return;
    }

    onClick(spreadsheetId);
  };

  const handleChange = () => {
    setError(null);
  };

  return (
    <Wrapper style={style}>
      {title && <Title>{title}</Title>}
      <Subtitle>{subtitle}</Subtitle>
      <InputWrapper>
        <Input onChange={handleChange} name="spreadsheet" placeholder={placeholder} />
        <Button onClick={handleClick} disabled={disableButton} help={buttonHelp}>
          {buttonText}
        </Button>
      </InputWrapper>
      <Error show={error}>{error}</Error>
    </Wrapper>
  );
};

CreateInput.defaultProps = {
  style: {},
  buttonHelp: '',
  title: null
};

CreateInput.propTypes = {
  buttonText: PropTypes.string.isRequired,
  disableButton: PropTypes.bool.isRequired,
  buttonHelp: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default CreateInput;
