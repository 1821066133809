import React, {useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import {toast} from 'react-toastify';
import styled from 'styled-components/macro';
import {boldTextColor} from '../modules/colors';
import media from '../modules/media';
import Layout from '../components/Layout';
import CreateInput from '../components/CreateInput';
import Button from '../components/Button';
import authenticate from '../modules/authenticate';
import request from '../modules/request';

const genericError = 'Something went wrong. Please make sure you used a correct Google Spreadsheet URL.';
const noConnectionError = 'Could not communicate with the server. Please make sure you have an internet connection.';
const permissionError =
  'We need your permission to access the spreadsheet. Use the example spreadsheet to give us access ONLY to that file.';
const invalidSpreadsheetId = 'Something went wrong. Are you using a correct Google Spreadsheet URL.';

const showErrorToast = error =>
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false
  });

const CreatePage = () => {
  const [loading, setLoading] = useState(false);

  const create = async spreadsheetId => {
    setLoading(true);

    toast.info('Working on it, wait please!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    });

    try {
      const response = await request('create', {
        spreadsheetId
      });

      // remove previous toasts
      toast.dismiss();

      if (response.status === 200) {
        toast.success('API created successfully!', {
          position: toast.POSITION.TOP_CENTER,
          onClose: () => navigate('/dashboard/')
        });
        // probably new user, authenticate him first
      } else if (response.status === 401) {
        authenticate(spreadsheetId);
      } else if (response.status === 400) {
        const responseJSON = await response.json();
        const error = responseJSON.error || genericError;

        showErrorToast(error);
      } else {
        showErrorToast(genericError);
      }
    } catch (error) {
      showErrorToast(noConnectionError);
    }

    setLoading(false);
  };

  useEffect(() => {
    // read url params
    const urlParams = new URLSearchParams(window.location.search);
    const serverError = urlParams.get('error');
    const spreadsheetId = urlParams.get('spreadsheetId');

    if (serverError) {
      // this error comes from authenticate.js
      if (serverError === 'invalidSpreadsheetId') {
        showErrorToast(invalidSpreadsheetId);
      } else {
        // we got a server error, probably related to permission
        showErrorToast(permissionError);
      }
    } else if (spreadsheetId) {
      // after successful permissions given, create the API again
      create(spreadsheetId);
    }
  }, []);

  return (
    <Layout>
      <Wrapper>
        <Title>Create a new GraphQL API</Title>
        <Table>
          <Option>
            <Subtitle>Create an example spreadsheet</Subtitle>
            <Button onClick={() => create('example')} disable={loading}>
              {loading ? 'Loading...' : 'Create example'}
            </Button>
            <Info>
              Using this method, you will be redirected to your google account to give GraphQLsheet permission to create
              a new spreadsheet. GraphQLsheet will have access to this file <strong>only</strong>. If you change the
              first row of the spreadsheet (e.g. by pasting your data) please remember to re-create the API through the
              dashboard.
            </Info>
          </Option>
          <Separator />
          <Option>
            <CreateInput
              title=""
              subtitle="Please paste the Google Spreadsheet URL below e.g.
https://docs.google.com/spreadsheets/.../edit"
              placeholder="The Google Spreasheet URL here"
              onClick={create}
              buttonText={loading ? 'Loading...' : 'Create'}
              disableButton={loading}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            />
            <Info>
              Using this method, you will be redirected to your google account to give GraphQLsheet permission to
              view/edit all your spreadsheet files to be able to access that specific spreadsheet.
            </Info>
          </Option>
        </Table>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  padding: 60px 20px 200px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${boldTextColor};
  font-size: 40px;

  ${media.tablet`font-size: 32px;`};
  ${media.phone`font-size: 24px;`};
`;

const Subtitle = styled.div`
  font-size: 20px;
  margin: 20px 0;
  padding: 10px 0 10px 0;
  white-space: pre-wrap;

  ${media.tablet`
    font-size: 18px;
    margin: 10px 0;
  `};

  ${media.phone`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  `};
`;

const Table = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;

  ${media.bigDesktop`
    width: 100%;
  `};

  ${media.desktop`
    flex-direction: column;
    align-items: center;
  `};
`;

const Option = styled.div`
  padding: 20px;
  width: 500px;

  ${media.phone`
    display: flex;
    flex-direction: column;
    width: 100%;
  `};
`;

const Info = styled.div`
  margin-top: 20px;
  font-size: 14px;
  padding-top: 10px;
`;

const Separator = styled.hr`
  height: 300px;
  border: 1px solid #ccc;
  margin-top: 30px;

  ${media.desktop`
    width: 100%;
    height: 0px;
  `};
`;

export default CreatePage;
