import React from 'react';
import styled from 'styled-components/macro';
import {gray} from '../modules/colors';

const Inner = styled.input`
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 20px;
  border-radius: 2px;
  padding: 8px 6px;
  box-shadow: none;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #DDE7EF;
  color: ${gray};
  max-width: 100%;
  width: 100%;

  &::placeholder {
    color: rgb(174, 185, 193);
  }
`;

const Input = props => <Inner {...props} />;

export default Input;
