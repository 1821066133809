const isValidSpreadsheetURL = value => {
  let spreadsheetId = value;
  let result;

  // check if we got a full url
  if (value.substr(0, 39) === 'https://docs.google.com/spreadsheets/d/') {
    spreadsheetId = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)').exec(value)[1];
    // sheetId = new RegExp("[#&]gid=([0-9]+)").exec(resourceUrl)[1];
  }

  if (/^([a-zA-Z0-9-_]+$)/.test(spreadsheetId) && spreadsheetId.length > 15) {
    result = spreadsheetId;
  } else {
    result = false;
  }

  return result;
};

export default isValidSpreadsheetURL;
